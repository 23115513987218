import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import './style.scss'

class Post extends React.Component {
  render() {
    const {
      title,
      date,
      category,
      description,
      description_image,
      layout
    } = this.props.data.node.frontmatter
    const excerpt = this.props.data.node.excerpt;
    const { slug, categorySlug } = this.props.data.node.fields

    let see_more_text;
    if (layout == "post_blog") {
        see_more_text = "Read";
    }
    else if (layout == "post_art") {
        see_more_text = "";
    }
    else {
        see_more_text = `<TODO: need text for post layout '${layout}'>`;
    }

    let post_image = null;
    if (description_image) {
        post_image = getImage(description_image);
    }

    return (
      <div className="post">
        <div className="post__meta">
          <time
            className="post__meta-time"
            dateTime={moment(date).format('MMMM D, YYYY')}
          >
            {moment(date).format('MMMM YYYY')}
          </time>
          <span className="post__meta-divider" />
          <span className="post__meta-category" key={categorySlug}>
            <Link to={categorySlug} className="post__meta-category-link">
              {category}
            </Link>
          </span>
        </div>
        <h2 className="post__title">
          <Link className="post__title-link" to={slug}>
            {title}
          </Link>
        </h2>
        {
            description ? <p className="post__description">{description}</p> : null
        }
        {
            post_image ?
                <Link className="post__description-image" to={slug}>
                  <GatsbyImage image={post_image} alt={title} loading={"eager"} />
                </Link>
            : null
        }
        <Link className="post__readmore" to={slug}>{see_more_text}</Link>
      </div>
    )
  }
}

export default Post
